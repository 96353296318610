import React, { useState } from 'react';
import { useOrderContext } from '../context/OrderContext';

import './ProductVariantSummary.scss';

const ProductVariant = ({ variant = {} }) => {
    const { quantities, setQuantity } = useOrderContext();
    const currentValue = quantities[variant.SKU] ?? '0';

    const [hasInputFocus, setInputFocus] = useState(false);

    return (
        <div className="product-variant-summary__wrapper">
            <div className="input-group input-group-sm">
                <input
                /* do sprawdzenia czemu value znika a onChange blokuje się bez variant SKU - dodać warunek */
                    value={hasInputFocus && currentValue == '0' ? '' : currentValue}
                    onChange={(e) => setQuantity(variant.SKU, e.target.value)}
                    onFocus={() => setInputFocus(true)}
                    onBlur={() => setInputFocus(false)}
                    className="form-control text-center" type="number" name={variant.SKU} />
            </div>
        </div>
    );
}


export default ProductVariant