import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';

import { useOrderContext } from '../context/OrderContext';
import SuccessModal from './SuccessModal';

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import './OrderForm.scss';
import { isPhoneValid } from '../utils/isPhoneValid';

const OrderForm = ({ cart }) => {
    const { quantities, data, setField, clearAll, isLocked, setLock, autocomplete } = useOrderContext()

    const [resErrors, setResErrors] = useState({});
    const [modalData, setOpenModal] = useState({});

    const [phone, setPhone] = useState(data.phone || "");

    useEffect(() => {
        if (!!window?.APP_SETTINGS?.user?.email) {
            setField('email', window?.APP_SETTINGS?.user?.email)
            setTimeout(() => autocomplete(true), 100);
        }
    }, []);

    const handleClearData = (e) => {
        e.preventDefault();
        clearAll();
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isLocked)
            return;

        setResErrors({});

        const formData = {
            portal_Id: window?.APP_SETTINGS?.portalSettings?.ID,
            email: data.email,
            phone: phone,
            billing_address: data.billingAddress,
            delivery_address: data.deliveryAddress,
            message: data.message,
            quantities: JSON.stringify({
                quantities: { ...quantities },
                cart: { ...cart }
            }),
            terms: data.terms ? 'YES' : 'NO',
            newsletter: data.newsletter ? 'YES' : 'NO'
        }

        const customConfig = {
            headers: {
                'Accept': 'application/json'
            }
        };

        console.log(
            "email: " + data.email, "\n",
            "phone: " + phone, "\n",
            "billing_address: " + data.billingAddress, "\n",
            "delivery_address: " + data.deliveryAddress, "\n",
            "terms: " + data.terms, "\n",
            "newsletter: " + data.newsletter, "\n",
        )

        if (isPhoneValid(phone)) {
            setLock(true);
            axios.post('/request/add', formData, customConfig)
                .then(({ data }) => {
                    if (data.errors) {
                        setResErrors(data.errors)
                        return false;
                    } else {
                        setOpenModal({ "message": "Thank you for your request. <br />Your request number is: " + data?.model?.order_number + "<br />Confirmation has been sent on your e-mail" });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => {
                    setLock(false);
                });
        } else {
            setResErrors({
                ...resErrors,
                phone: 'Phone is not valid'
            });
        }
    }

    const handleChange = (event) => {
        setField(event.target.name, event.target.value);
    }
    const handleChangeCheckbox = (event) => {
        setField(event.target.name, event.currentTarget.checked);
    };

    const handleAutocomplete = (event) => {
        const value = event?.target?.value;
        const type = event?.target?.type;
        if (!value || value.length <= 0)
            return;

        if (type == 'tel' && !isPhoneValid(value))
            return;

        autocomplete();
    }

    const mailError = resErrors.hasOwnProperty('email');
    const phoneError = resErrors.hasOwnProperty('phone');
    const billingAddressError = resErrors.hasOwnProperty('billing_address');
    const deliveryAddressError = resErrors.hasOwnProperty('delivery_address');
    const termsError = resErrors.hasOwnProperty('terms');

    return <>
        <h2>Send your request</h2>

        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <div className="form-floating">
                    <input value={data.email}
                        onChange={handleChange} onBlur={handleAutocomplete}
                        type="email" name="email" className={`form-control ${mailError && 'border-danger'}`} id="OrderFormEmail" placeholder="user@gmail.com"
                        disabled={!!window?.APP_SETTINGS?.user?.email || isLocked}
                    />
                    <label htmlFor="OrderFormEmail">Email address*:</label>
                </div>
                {mailError && (
                    <p className="text-danger">{resErrors.email[0]}</p>
                )}
            </div>
            <div className='mb-3'>
                <div className="form-floating ">
                    <PhoneInput
                        defaultCountry="pl"
                        name="phone"
                        id="OrderFormPhone"
                        className={`form-control ${phoneError && 'border-danger'}`}
                        value={data.phone}
                        onChange={phone => setPhone(phone) & setField("phone", phone)}
                        inputProps={{
                            onBlur: handleAutocomplete,
                            value: data.phone
                        }}
                    />
                    <label htmlFor="OrderFormPhone">Phone number*:</label>
                </div>
                {phoneError && (
                    <p className="text-danger">Phone is not valid</p>
                )}
            </div>
            <div className='mb-3'>
                <div className="form-floating ">
                    <textarea value={data.billingAddress} onChange={handleChange} name="billingAddress" className={`form-control ${billingAddressError && 'border-danger'}`} id="OrderFormBillingAddress" placeholder="Your address" style={{ height: 100 }}
                        disabled={isLocked}
                    ></textarea>
                    <label htmlFor="OrderFormBillingAddress">Billing address* (for proforma invoice):</label>
                </div>
                {billingAddressError && (
                    <p className="text-danger">{resErrors.billing_address[0]}</p>
                )}
            </div>

            <div className='mb-3'>
                <div className="form-floating">
                    <textarea value={data.deliveryAddress} onChange={handleChange} name="deliveryAddress" className={`form-control ${deliveryAddressError && 'border-danger'}`} id="OrderFormDeliveryAddress" placeholder="Your address" style={{ height: 100 }}
                        disabled={isLocked}
                    ></textarea>
                    <label htmlFor="OrderFormDeliveryAddress">Delivery address* (for shipping estimation):</label>
                </div>
                {deliveryAddressError && (
                    <p className="text-danger">{resErrors.delivery_address[0]}</p>
                )}
            </div>
            <div className='mb-3'>
                <div className="form-floating">
                    <textarea value={data.message} onChange={handleChange} name="message" className="form-control" id="OrderFormMessage" placeholder="Message" style={{ height: 100 }}
                        disabled={isLocked}
                    ></textarea>
                    <label htmlFor="OrderFormMessage">Additional requests:</label>
                </div>
            </div>

            <div className="mb-3">
                <div className={`form-check ${termsError && 'form-check--error'}`}>
                    <input value={data.terms} checked={data.terms} onChange={handleChangeCheckbox} name="terms" type="checkbox" className="form-check-input" id="OrderFormTerms"
                        disabled={isLocked}
                    />
                    <label className="form-check-label" htmlFor="OrderFormTerms">I accept the terms and condition of this service*</label>
                </div>
                {termsError && (
                    <p className="text-danger">{resErrors.terms[0]}</p>
                )}
            </div>

            <div className="mb-3 form-check">
                <input value={data.newsletter} checked={data.newsletter} onChange={handleChangeCheckbox} name="newsletter" type="checkbox" className="form-check-input" id="OrderFormNewsletter"
                    disabled={isLocked}
                />
                <label className="form-check-label" htmlFor="OrderFormNewsletter">Sign me in to the B2B newsletter</label>
            </div>

            <div className="d-grid gap-2">
                <button type="submit" className="btn btn-primary btn" disabled={isLocked}>
                    Send request {isLocked && (
                        <div class="spinner-border spinner-border-sm" role="status" />
                    )}
                </button>
                <br /><br /><br /><br />
                <button onClick={handleClearData} className="btn btn-link btn-sm" disabled={isLocked}>
                    Clear form
                </button>
            </div>
        </form>
        <SuccessModal modalData={modalData} />
    </>;
}


export default OrderForm